<template>
  <footer class="footer">
    <div class="footer__content-wrapper">
      <section class="footer__content">
        <div class="footer__main">
          <LogoSmall />

          <div
            v-if="navigationMenu.length"
            class="footer__menu"
          >
            <T3Link
              v-for="link in navigationMenu"
              :key="link.title"
              :to="link"
              class="footer__menu-link"
            >
              {{ link.title }}

              <span
                v-if="showCarrerBadge(link.title)"
                class="footer__badge"
              >
                {{ locale.openRoles }}
              </span>
            </T3Link>
          </div>

          <UiSocials
            :socials="socialMenu"
            class="footer__socials footer__socials--top"
          />

          <div
            v-if="contactData.length"
            class="footer__contact-wrapper"
          >
            <address
              v-for="(item, index) in contactData"
              :key="item.id"
              class="footer__contact"
            >
              <p class="footer__contact-office">
                <span>{{ item.name }}</span>

                <span
                  v-if="!index"
                  class="footer__badge"
                >
                  {{ locale.mainOffice }}
                </span>
              </p>

              <T3HtmlParser
                v-if="item.text"
                :content="item.text"
                class="footer__contact-details"
              />
            </address>
          </div>
        </div>

        <div class="footer__newsletter">
          <slot name="newsletter" />
        </div>
      </section>
    </div>

    <div class="footer__copyrights-wrapper">
      <div class="footer__copyrights">
        <span>
          {{ isMainPage ? null : locale.copyrightsPrefix }} Macopedia &copy;
          {{ currentYear }}
        </span>

        <T3Link
          v-if="isMainPage"
          :to="locale.link"
        >
          {{ locale.policy }}
        </T3Link>

        <UiSocials
          :socials="socialMenu"
          class="footer__socials footer__socials--bottom"
        />

        <div
          v-if="!isMainPage"
          class="footer__trademark"
        >
          <p>TYPO3 is a trademark of the TYPO3 Association.</p>

          <p>
            TYPO3 Headless is an open-source community initiative and is not an
            official TYPO3 Association product.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { UiSocials } from '~ui/components'

export default {
  components: { UiSocials },
  props: {
    contactData: {
      type: Array,
      default: () => []
    },
    navigationMenu: {
      type: Array,
      default: () => []
    },
    socialMenu: {
      type: Array,
      default: () => []
    },
    locale: {
      type: Object,
      default: () => ({})
    },
    isMainPage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    showCarrerBadge(title) {
      return !!this.locale.career.includes(title)
    }
  }
}
</script>

<style lang="scss">
.footer {
  $logo-width: rem(30px);

  position: relative;
  z-index: z-index(over);
  background-color: color(alabaster);

  &__content-wrapper,
  &__copyrights-wrapper {
    @include frame-content-container(large);
  }

  &__content,
  &__copyrights {
    margin-inline: auto;
  }

  &__content {
    display: flex;
    padding-block: rem(80px);
    justify-content: space-between;

    @include media-query(max-width lg) {
      flex-direction: column;
      gap: rem(48px);
    }
  }

  &__main {
    display: grid;
    grid-template: repeat(2, auto) / $logo-width 1fr;
    align-items: center;
    grid-template-areas:
      'logo menu'
      '. contact';
    gap: rem(24px) rem(48px);
    padding-bottom: rem(56px);

    @include media-query(max-width md) {
      grid-template: 1fr / 1fr;
      margin-bottom: rem(24px);
      grid-template-areas:
        'logo'
        'menu'
        'socials'
        'contact';
      gap: rem(40px);
      padding-bottom: 0;
    }

    & svg {
      width: $logo-width;
      grid-area: logo;
    }
  }

  &__badge {
    font-size: rem(12px);
    padding: em(8px);
    color: color(primary);
    border-radius: em(2px);
    border: 1px solid rgba(color(primary), 64%);
    background-color: rgba(color(primary), 4%);
    margin-left: rem(8px);
    text-transform: uppercase;
  }

  &__socials {
    grid-area: socials;

    & .ui-socials__list {
      justify-content: unset;
    }

    @include media-query(max-width sm) {
      margin-block: rem(16px);
    }

    &--top {
      @include media-query(sm) {
        &.ui-socials {
          display: none;
        }
      }

      & .ui-socials__item a img {
        filter: invert(0);
      }
    }

    &--bottom {
      @include media-query(max-width sm) {
        &.ui-socials {
          display: none;
        }
      }

      & .ui-socials__item a:is(:hover, :active, :focus) img {
        filter: color(primary, $colors-filter) saturate(0) invert(1)
          brightness(200%);
      }
    }
  }

  &__menu {
    display: flex;
    gap: clamp(rem(12px), 0.9vw, rem(24px));
    grid-area: menu;
    height: min-content;

    @include media-query(xl) {
      gap: rem(24px);
    }

    @include media-query(max-width md) {
      gap: rem(24px);
      max-width: rem(320px);
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
    }

    &-link {
      font-weight: 600;
      font-size: rem(14px);
      text-transform: uppercase;
      width: fit-content;
      flex-shrink: 0;
    }
  }

  &__newsletter {
    max-width: rem(460px);

    @include media-query(max-width lg) {
      margin-left: calc($logo-width + rem(48px));
      max-width: 100%;
    }

    @include media-query(max-width md) {
      margin-left: 0;
    }
  }

  &__contact {
    &-wrapper {
      display: flex;
      gap: rem(32px);
      grid-area: contact;
      margin-bottom: auto;

      @include media-query(max-width sm) {
        flex-direction: column;
      }
    }

    min-width: rem(256px);
    font-style: normal;

    &-office {
      font-weight: 600;
      font-size: rem(16px);
      margin-bottom: rem(16px);
    }

    &-details p {
      color: hsl(0 0% 0% / 64%);
      font-size: rem(14px);
      line-height: 1.65;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__copyrights {
    &-wrapper {
      background-color: color(primary);
    }

    padding-block: rem(32px);
    display: flex;
    gap: rem(16px);

    & > span {
      margin-right: rem(32px);
      color: color(white);
      font-weight: 600;
    }

    & > a {
      color: hsl(0 0% 100% / 80%);

      &:hover {
        color: color(alabaster);
        text-decoration: underline;
      }
    }

    & > .footer__socials {
      margin-inline: auto;

      &:last-child {
        margin-right: 0;
      }
    }

    & .footer__trademark p {
      font-size: rem(10px);
      color: color(alabaster);
      margin: 0;
      text-align: right;
    }
  }
}
</style>
